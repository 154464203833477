<template>
  <div class="tree-warehouse-location">
    <b-row class="my-1 tree-warehouse-location__row">
      <b-col cols="8">
        <b-row
          :style="`margin-left: ${16 * depth}px`"
          class="align-items-center tree-warehouse-location__row"
        >
          <!--            ICON FOR SHOW IT IS NESTED NODE-->
          <feather-icon
            v-if="depth > 1"
            icon="LTreeLineIcon"
            size="16"
            class="lightIcon m-0 mr-1"
          />
          <!--            NAME OF NODE-->
          <template v-if="isChanging && isWarehouseLocationsChanging && isWarehouseLocationsTyping">
            <l-text-input
              v-model="localName"
              :name="depth + 'level_name'"
              class="name__input pr-1"
              rules="required"
              :field="{
                placeholder: 'Type a Name'
              }"
            />
          </template>
          <template v-else>
            <span>{{ node.level_name }}</span>
          </template>
          <!--            PLUS ICON TO ADD NODE-->
          <button-dashed
            v-show="!isWarehouseLocationsTyping && isWarehouseLocationsChanging && depth < 5"
            class="add-icon ml-1"
            @click.prevent="createChildNode"
          >
            <feather-icon
              icon="LPlusPureIcon"
              size="16"
              class="lightIcon lightIcon--stroke m-0"
            />
          </button-dashed>
        </b-row>
      </b-col>
      <b-col
        cols="2"
        class="text-center"
      >
        <template v-if="isChanging && isWarehouseLocationsChanging && isWarehouseLocationsTyping">
          <l-text-input
            v-model="localReferenceName"
            :name="depth + 'reference_name'"
            rules="required|max:2"
            class="name__input pr-1"
            :field="{
              placeholder: 'Type a Name'
            }"
          />
        </template>
        <template v-else>
          {{ node.reference_name }}
        </template>
      </b-col>

      <b-col
        cols="2"
        class="text-center"
      >
        <div class="d-flex justify-content-center">
          <b-form-checkbox
            v-model="node.is_active"
            :disabled="!isWarehouseLocationsChanging"
          />
          <!--        Delete and Edit Btn-->
          <div v-show="isWarehouseLocationsChanging && !isChanging && !isWarehouseLocationsTyping">
            <div class="d-flex ">
              <!--        DELETE BUTTON-->
              <button-dashed
                class="add-icon ml-1"
                @click.prevent="$emit('deleteNode')"
              >
                <feather-icon
                  icon="LTrashIcon"
                  size="16"
                  class="lightIcon m-0"
                />
              </button-dashed>
              <!--        EDIT BUTTON-->
              <button-dashed
                class="add-icon ml-1"
                @click.prevent="changeTreeLocation"
              >
                <feather-icon
                  icon="LPenIcon"
                  size="16"
                  class="lightIcon m-0"
                />
              </button-dashed>
            </div>
          </div>
          <!--        Approve and Reject Btn-->
          <div v-show="isChanging && isWarehouseLocationsChanging">
            <div class="d-flex">
              <!--        REJECT BUTTON-->
              <button-dashed
                class="add-icon ml-1"
                @click.prevent="rejectChanges"
              >
                <feather-icon
                  icon="LClearIcon"
                  size="16"
                  class="lightIcon m-0"
                />
              </button-dashed>
              <!--       APPROVE BUTTON-->
              <button-dashed
                v-show="localName.length > 0 && localReferenceName.length > 0"
                class="add-icon ml-1"
                @click.prevent="acceptChanges"
              >
                <feather-icon
                  icon="LCheckIcon"
                  size="16"
                  class="lightIcon m-0"
                />
              </button-dashed>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <tree-warehouse-location
      v-for="(child, idx) in node.children"
      :key="child.level_name"
      :depth="depth + 1"
      :node="child"
      @deleteNode="deleteNOde(idx)"
    />
  </div>

</template>
<script>
import ButtonDashed from '@/@core/components/b-button-dashed/ButtonDashed.vue'
import LTextInput from '@/views/components/DynamicForm/components/LTextInput.vue'
import { BRow, BCol, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'TreeWarehouseLocation',
  components: {
    ButtonDashed,
    BRow,
    BCol,
    LTextInput,
    BFormCheckbox,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isChanging: false,
      localName: '',
      localReferenceName: '',
    }
  },
  computed: {
    isWarehouseLocationsTyping() {
      return this.$store.state.warehouse.isWarehouseLocationsTyping
    },
    isWarehouseLocationsChanging() {
      return this.$store.state.warehouse.isWarehouseLocationsChanging
    },
  },
  watch: {
    isWarehouseLocationsChanging(val) {
      if (!val) {
        this.isChanging = false
      }
    },
  },
  created() {
    if (this.node.level_name === null || this.node.reference_name === null) {
      this.changeTreeLocation()
    }
  },
  methods: {
    createChildNode() {
      if (!Array.isArray(this.node.children)) {
        this.$set(this.node, 'children', [])
      }
      this.node.children.push({
        level_name: null,
        reference_name: null,
        is_active: false,
      })
    },
    deleteNOde(idx) {
      this.node.children.splice(idx, 1)
    },
    changeTreeLocation() {
      this.changeStateOfChanging(true)
      if (typeof this.node.level_name === 'string') {
        this.localName = this.node.level_name
      }
      if (typeof this.node.reference_name === 'string') {
        this.localReferenceName = this.node.reference_name
      }
    },
    acceptChanges() {
      this.node.level_name = this.localName
      this.node.reference_name = this.localReferenceName
      this.changeStateOfChanging(false)
    },
    rejectChanges() {
      this.localName = ''
      this.localReferenceName = ''
      this.changeStateOfChanging(false)
      if (this.node.reference_name === null || this.node.level_name === null) {
        this.$emit('deleteNode')
      }
    },
    changeStateOfChanging(booleanData) {
      if (typeof booleanData === 'boolean') {
        this.$store.commit('warehouse/SET_LOCATION_TYPING_STATE', booleanData)
        this.isChanging = booleanData
      }
    },
  },
}
</script>

<style lang="scss">
  .tree-warehouse-location{
    &__row{
      min-height: 38px;
    }
    .add-icon {
      padding: 10px;
    }
    .name__input{
      width: 158px;
      margin-bottom: 0;
    }
  }
</style>
