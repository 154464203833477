import {
  L_ACTIVE_CHECK_LF_BOX, L_PHONE_MASK_INPUT, SELECT_CHILD_INPUT, SELECT_INPUT, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const ACCESS_ABILITY_WAREHOUSE = { action: 'change', subject: 'Warehouse' }
  // Table Handlers
  const tableColumns = [
    { key: 'actions', label: '', thStyle: { width: '50px' } },
    { key: 'label', label: 'Name' },
    { key: 'address', label: 'Address' },
    { key: 'phone', label: 'Phone', thStyle: { 'text-align': 'center' } },
    { key: 'two_letter_code', label: 'Two letter code', thStyle: { 'text-align': 'center' } },
  ]

  const fields = {
    label: {
      type: TEXT_INPUT,
      label: 'Warehouse Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    country_id: {
      type: SELECT_INPUT,
      label: 'Country',
      rules: 'required',
      store: 'countryList',
    },
    street: {
      type: TEXT_INPUT,
      label: 'Street Address',
      rules: 'required',
      placeholder: 'Type...',
    },
    city: {
      type: TEXT_INPUT,
      label: 'City',
      rules: 'required',
      placeholder: 'Type...',
    },
    state_id: {
      type: SELECT_CHILD_INPUT,
      label: 'State',
      rules: 'required',
      store: 'stateList',
    },
    two_letter_code: {
      type: TEXT_INPUT,
      label: 'Two letter code',
      rules: 'required',
      placeholder: 'Type...',
    },
    zip_code: {
      type: TEXT_INPUT,
      label: 'Postal Code',
      rules: 'required',
      placeholder: 'Type...',
    },
    status: {
      type: L_ACTIVE_CHECK_LF_BOX,
      label: 'Active',
      rules: 'required',
    },
    phone: {
      type: L_PHONE_MASK_INPUT,
      label: 'Phone Number',
      rules: 'required',
      placeholder: 'Type...',
    },
  }
  const setObjectOfPhoneMusk = (mask, formName, fieldName) => {
    if (!mask) return

    // eslint-disable-next-line no-param-reassign
    formName[fieldName] = mask
  }
  return {
    setObjectOfPhoneMusk,
    tableColumns,
    fields,
    ACCESS_ABILITY_WAREHOUSE,
  }
}
