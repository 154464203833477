<template>
  <div>
    <p class="text-center font-weight-bolder font-large-1">
      {{ isUpdate ? $t('Update a Warehouse') : $t('Add a Warehouse') }}
    </p>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="warehouseCreateVal"
      >
        <div id="general-information">
          <h4 class="font-weight-bolder">
            {{ $t('General Information') }}
          </h4>
          <div class="theWarehouseCheckbox">
            <b-row>
              <b-col
                v-for="field in [
                  'label',
                  'country_id',
                  'street',
                  'city',
                  'state_id',
                  'two_letter_code',
                  'zip_code',
                  'status',
                ]"
                :key="field"
                md="6"
              >
                <component
                  :is="fields[field].type"
                  v-model="warehouseForm[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit"
                />
              </b-col>
              <b-col
                v-for="field in ['phone']"
                :key="field"
                md="6"
                class="d-flex"
              >
                <div class="w-75">
                  <component
                    :is="fields[field].type"
                    v-model="warehouseForm[field]"
                    v-bind="getProps(field)"
                    :is-editable="isEdit"
                    :default-country-code="getDefaultCountryCode()"
                    @input-obj="setObjectOfPhoneMusk($event, warehouseForm,'phone_no_object')"
                  />
                </div>
                <div class="w-25">
                  <label class="pt-0">{{ $t('Ext number') }}</label>
                  <b-form-input
                    v-model="warehouseForm[`${field}_ext_val`]"
                    class="mb-1"
                    style="height: 38px"
                    placeholder="ext number"
                    :disabled="isEdit"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </div>

        <div class="mb-2">
          <h4 class="font-weight-bolder">
            {{ $t('Location management') }}
          </h4>
          <b-row>
            <div
              v-show="!isWarehouseLocationsChanging"
              class="ml-auto"
            >
              <button-dashed class="d-inline-block">
                <feather-icon
                  icon="LPrintIcon"
                  size="16"
                  class="lightIcon"
                />
                {{ $t('Print') }}
              </button-dashed>
              <button-dashed class="mx-1 d-inline-block">
                <feather-icon
                  icon="LImportIcon"
                  size="16"
                  class="lightIcon"
                />
                {{ $t('Import') }}
              </button-dashed>
              <b-button
                @click="changeWarehouseLocation"
              >
                <feather-icon
                  icon="LPenIcon"
                  size="16"
                />
              </b-button>
            </div>
            <div
              v-show="isWarehouseLocationsChanging"
              class="ml-auto"
            >
              <b-button
                class="mr-1"
                @click="rejectChangesOfWarehouseLocation"
              >
                <feather-icon
                  icon="LClearIcon"
                  size="16"
                />
              </b-button>
              <b-button
                :disabled="isWarehouseLocationsTyping"
                variant="success"
                @click="saveWarehouseLocation"
              >
                {{ $t('Save') }}
              </b-button>
            </div>
          </b-row>
        </div>

        <div>
          <b-row class="mb-2">
            <b-col cols="8">
              <span class="font-weight-bolder">{{ $t('Level Name') }}</span>
            </b-col>
            <b-col cols="2">
              <span class="font-weight-bolder">{{ $t('Reference Name') }}</span>
            </b-col>
            <b-col cols="2">
              <span class="font-weight-bolder">{{ $t('Receives Assets') }}</span>
            </b-col>
          </b-row>
          <tree-warehouse-location
            v-for="(node, idx) in warehouseForm.locations"
            :key="node.level_name"
            :depth="1"
            :node="node"
            @deleteNode="deleteNode(idx)"
          />
          <button-dashed
            v-show="!isWarehouseLocationsTyping && isWarehouseLocationsChanging"
            class="add-icon ml-1"
            @click.prevent="createChildNode"
          >
            <feather-icon
              icon="LPlusPureIcon"
              size="16"
              class="lightIcon lightIcon--stroke m-0"
            />
          </button-dashed>
        </div>

        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="clear"
              >
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="isCreateForm ? false : !isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>

import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BForm, BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import ButtonDashed from '@/@core/components/b-button-dashed/ButtonDashed.vue'
import { scrollToError } from '@core/utils/utils'
import TreeWarehouseLocation from '@/views/settings/fulfillment/warehouses/components/TreeWarehouseLocation.vue'
import jsonToFormData from '@/libs/jsonToFormData'
import config from '../warehouseConfig'

export default {
  name: 'WarehouseForm',
  components: {
    BFormInput,
    TreeWarehouseLocation,
    ErrorAlert,
    ValidationObserver,
    ButtonDashed,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      error: {},
      nodes: [
        {
          level_name: 'One',
          reference_name: 'One',
          is_active: false,
        },
      ],
      locationClone: null,
      onSubmit: false,
    }
  },
  computed: {
    warehouse() {
      return this.$store.state[this.MODULE_NAME].warehouse
    },
    warehouseForm() {
      return this.$store.state[this.MODULE_NAME].warehouseForm
    },
    warehouseClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingWarehouse
    },
    isFormChanged() {
      return JSON.stringify(this.warehouseForm) !== this.warehouseClone
    },
    isUpdate() {
      return this.warehouseForm.id
    },
    isWarehouseLocationsChanging() {
      return this.$store.state[this.MODULE_NAME].isWarehouseLocationsChanging
    },
    isWarehouseLocationsTyping() {
      return this.$store.state[this.MODULE_NAME].isWarehouseLocationsTyping
    },
  },
  mounted() {
    if (!this.isCreateForm) {
      const data = {
        ...this.warehouseForm,
        ...{
          phone_ext_val: this.warehouse?.phone_no_object?.phone_no_ext_val ?? null,
        },
      }
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, data)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_WAREHOUSE_FORM_CLONE`, data)
      this.getDefaultCountryCode()
    }
  },
  methods: {
    getDefaultCountryCode(countryCode) {
      if (!this.isCreateForm) {
        return countryCode ? 'US' : this.warehouse?.phone_no_object?.countryCode ?? 'US'
      }
      return countryCode ?? 'US'
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    refetchWarehouseLocations() {
      this.$store.dispatch('horizontalMenu/getWarehousesList')
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.warehouseCreateVal.validate().then(success => {
        if (success) {
          const data = this.warehouseForm
          const warehouseForm = this.mappingFields([...Object.keys(this.fields), 'locations'], data, {
            country_id: data.country_id?.id,
            state_id: data.state_id?.id,
            id: data.id,
            status: data.status ? 1 : 0,
            phone_no_object: JSON.stringify({ ...data.phone_no_object, phone_no_ext_val: data.phone_ext_val }),
            locations: data.locations.map(location => ({
              ...location,
              is_active: location.is_active ? 1 : 0,
            })),
          })
          // eslint-disable-next-line new-cap
          const request = new jsonToFormData(warehouseForm)

          this.sendNotification(this, request, `${this.MODULE_NAME}/${this.isUpdate ? 'updateWarehouse' : 'createWarehouse'}`)
            .then(response => {
              if (this.isCreateForm) {
                this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
                this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_WAREHOUSE_FORM_CLONE`, {})
                this.$router.push({ name: 'settings-fulfillment-warehouses-list' })
              }
              const { data } = response.data

              const resp = this.mappingFields(Object.keys(this.fields), data, {
                id: data.id,
                country_id: data.country,
                state_id: data.state,
                phone_no_object: data.phone_no_object,
                locations: data.locations,
              })
              this.resetDate(resp)
            }).catch(err => {
              this.error = err.response?.data
            }).finally(() => {
              this.onSubmit = false
              // this.refetchWarehouseLocations()
            })
        } else {
          scrollToError(this, this.$refs.warehouseCreateVal)
        }
      })
    },
    loader() {
      this.$refs.warehouseCreateVal.reset()
      const smth = JSON.parse(this.warehouseClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    resetDate(data) {
      const mutationDate = {
        ...data,
        ...{ phone_ext_val: data?.phone_no_object?.phone_no_ext_val },
      }
      this.getDefaultCountryCode(mutationDate?.phone_no_object?.countryCode ?? 'US')
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, mutationDate)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_WAREHOUSE_FORM_CLONE`, mutationDate)
    },
    clear() {
      this.$refs.warehouseCreateVal.reset()
      this.changeStateOfChanging(false)
      this.changeStateOfTyping(false)
      this.changeStateOfInputChanging(false)
      this.$router.push({ name: 'settings-fulfillment-warehouses' })
    },
    getProps(fieldName) {
      const data = {
        field: this.fields[fieldName],
        name: fieldName,
      }
      if (fieldName === 'state_id') {
        data.parentValue = this.warehouseForm.country_id?.id
      }
      return data
    },
    changeStateOfChanging(booleanData) {
      this.$store.commit('warehouse/SET_LOCATION_STATE', booleanData)
    },
    changeStateOfTyping(booleanData) {
      this.$store.commit('warehouse/SET_LOCATION_TYPING_STATE', booleanData)
    },
    changeStateOfInputChanging(booleanData) {
      this.$store.commit('warehouse/SET_LOCATION_INPUT_CHANGING_STATE', booleanData)
    },
    createChildNode() {
      this.warehouseForm.locations.push({
        level_name: null,
        reference_name: null,
        is_active: false,
      })
    },
    deleteNode(idx) {
      this.warehouseForm.locations.splice(idx, 1)
    },
    changeWarehouseLocation() {
      if (!Array.isArray(this.warehouseForm.locations)) {
        this.$set(this.warehouseForm, 'locations', [])
      }
      this.locationClone = JSON.parse(JSON.stringify(this.warehouseForm.locations))
      this.changeStateOfChanging(true)
      this.changeStateOfInputChanging(true)
    },
    saveWarehouseLocation() {
      this.changeStateOfChanging(false)
    },
    rejectChangesOfWarehouseLocation() {
      this.warehouseForm.locations = this.locationClone
      this.changeStateOfChanging(false)
      this.changeStateOfTyping(false)
      this.changeStateOfInputChanging(false)
    },
  },
  setup() {
    const MODULE_NAME = 'warehouse'
    const MODULE_NAME_CLONE = 'cloneData'

    const { fields, setObjectOfPhoneMusk } = config()
    return {
      setObjectOfPhoneMusk,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>

<style>
#general-information .theWarehouseCheckbox .checkboxGroup .form-group .inputLabel {
  padding-left: 10px !important;
}
</style>
